.nav {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 480px;
  margin: auto;
  margin-bottom: 2rem;
  position: relative;
  &:after {
    content: ' ';
    width: 100%;
    height: 1px;
    display: block;
    z-index: 1;
    position: absolute;
    background-color: var(--theme-deafult);
  }
  .dot {
    color: var(--white);
    cursor: pointer;
    font-size: 1rem;
    transition: all 1s ease;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    border: 1px solid var(--theme-deafult);
    background-color: var(--white);
    color: var(--theme-deafult);
    &.active {
      background-color: var(--theme-deafult);
      color: var(--white);
      box-shadow: 0px 0px 8px var(--theme-deafult);
    }
  }
}

