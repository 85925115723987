.formContainer {
  gap: 16px;
  flex-wrap: wrap;
  :global {
    .custom-row {
      margin: 0;
      padding: 16px 0;
      border: 1px solid #d8d8d8;
      border-radius: 4px;
      width: 49%;
    }
    .personal-identification {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-end;
      justify-content: center;
      padding: 0 32px;
      div {
        padding: 0;
        &.identification-selector {
          select {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        &.identification-expire {
          input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
        &.identification {
          input {
            border-left: none;
            border-right: none;
            border-radius: 0;
          }
        }
      }
    }
  }
}

.advice {
  color: #858585;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  text-align: center;
  i {
    font-size: 1.2rem;
    margin-top: -4px;
  }
  span {
    font-style: italic;
    max-width: 360px;
  }
}

.actionContainer {
  margin-top: 2rem;
  flex: 0 0 100%;
  :global {
    .actions {
      gap: 1rem;
    }
  }
}

.favoriteButton {
  color: #6b778c !important;
  background-color: #f3f3f3 !important;
  border: 1px solid #d8d8d8 !important;

  :global {
    span,
    i {
      font-size: 16px;
      margin-right: 0.5rem !important;
    }
  }

  &:hover {
    background-color: #1fa8e9 !important;
    color: white !important;
  }
}
