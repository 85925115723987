@import '~bootstrap-scss/bootstrap.scss';

.phoneField {
  div:first-child {
    @extend .btn;
    display: flex;
    background-color: #fafbfc;
    border: 2px solid #dfe1e6;
  }
  input {
    @extend .form-control;
    background-color: #fafbfc;
    border: 2px solid #dfe1e6;
  }
}
