.button__tags {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 1.5rem 0;
  .button__tag {
    input[type="radio"] {
      display: none;
    }

    input[type="radio"]:checked + label {
      background-color: var(--theme-deafult);
    }

    label {
      border-radius: 1.5rem;
      padding: 0.5rem 1rem;
      background-color: var(--theme-deafult);
      color: #fff;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover,
      &:focus {
        background-color: var(--primary) !important;
        outline: 2px solid #fff;
        box-shadow: 0 0 8px var(--theme-deafult);
      }
    }
  }
}
