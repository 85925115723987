@import '~bootstrap-scss/bootstrap.scss';

.formGroup {
  margin-bottom: 1rem;
  :global(.StripeElement) {
    @extend .form-control;
    background-color: #fafbfc;
    border: 2px solid #dfe1e6;
    height: auto;
  }
  :global(.StripeElement--focus) {
    border-color: $primary;
  }
  :global(.StripeElement--invalid) {
    border-color: $danger;
  }
  :global(.StripeElement--complete) {
    border-color: $success;
  }
}

.twoColumns {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  .formGroup {
    flex: 1;
  }
}
