.favoriteButton {
  color: #6b778c !important;
  background-color: transparent !important;
  border: 1px solid transparent !important;
  :global {
    span,
    i {
      font-size: 16px;
      margin-right: 0.5rem !important;
    }
  }

  &:hover {
    background-color: #1fa8e9 !important;
    color: white !important;
  }
}
