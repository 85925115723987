@import '~bootstrap-scss/bootstrap.scss';
@import 'color';
@import 'vendors/chartist/chartist';
@import 'vendors/themify.scss';
@import 'vendors/whether-icon.scss';
@import 'vendors/icofont.scss';
@import 'vendors/flag-icon.scss';
@import 'vendors/icoicon/icons';
@import 'vendors/fontawesome.scss';
@import 'vendors/animate/animate';
@import 'vendors/sticky/sticky';
@import 'vendors/todo/todo';

.bg-neutral {
  background-color: $bg-neutral !important;
}

.text-neutral {
  color: #7a869a;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

:root {
  --font-family-sans-serif: 'Poppins', sans-serif;
}

body {
  font-family: var(--font-family-sans-serif);
}

form {
  label {
    font-size: 12px;
    font-weight: 600;
    color: #6b778c;
  }
  .form-control {
    background-color: #fafbfc;
    border: 2px solid #dfe1e6;
  }
}
