.selectable__card {
  border: 1px solid transparent !important;
  transition: all 0.5s ease-in-out;
  input[type="radio"] {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
  &:hover,
  &.selected {
    border: 1px solid var(--theme-deafult) !important;
  }
  .selectable__cardbody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
}
