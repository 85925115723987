.cardContainer {
  display: flex;
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  :global {
    .card-description {
      display: flex;
      gap: 1rem;
      align-items: center;

      .state {
        font-size: 1.5rem;
        color: #51bb25;
      }

      .user-icon {
        background-color: #e0f6ff;
        color: #1fa8e9;
        border-radius: 80px;
        padding: 8px 16px;
        font-size: 1.2rem;
        font-weight: bold;
      }

      .record-info {
        &__user-name {
          color: #255c94;
          font-weight: 600;
        }
        &__date {
          color: #6b778c;
          font-weight: 400;
        }
      }
    }
    .card-action {
      display: flex;
      flex-flow: column wrap;
      align-items: flex-end;

      .amount {
        color: #858585;
        font-size: 1.5rem;
        font-weight: 600;
      }

      .button-link {
        color: #255c94;
        background-color: white;
        border: none;
        cursor: pointer;
        padding: 0;
        text-decoration: underline;
      }
    }
  }
}
