.textfield {
  border-radius: 3px !important;
  border: 2px solid #d1d1d1 !important;
  color: gray !important;
}

.background {
  background-color: #1fa8e9;
  background-image: url('../../../assets/images/login//login_bg_mid.jpg');
  background-repeat: no-repeat;
  background-position: center !important;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.messageLine {
  border: 2px solid white;
  top: 3.6vw;
  color: white;
  text-align: left;
  letter-spacing: 0px;
  position: absolute;
  left: 80px;
  height: 6.3vw;
}

.messageLabel {
  font-size: 1.5vw;
  width: 140px;
  line-height: 30px;
  top: 4.3vw;
  color: white;
  text-align: left;
  letter-spacing: 0px;
  position: absolute;
  left: 100px;
}

.mainImage {
  position: absolute;
  z-index: 100;
  transform: translateY(-50%);
  top: 50%;
}

.subtitle {
  color: #6b778c;
  letter-spacing: 0px;
  padding-left: 20%;
  padding-right: 20%;
  text-align: center;
}

.forgotPassword {
  text-decoration: underline !important;
  color: #6b778c;
  font: normal normal normal 11px/17px Poppins !important;
}

.connectWith {
  color: #1fa8e9;
  font: normal normal normal 15px/19px Poppins;
  text-align: center;
  margin-top: 5px;
}

.dotedLine {
  border: 1px dashed #1fa8e9;
  height: 1px;
}
