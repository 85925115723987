.navTabs {
  .moduleNameContainer {
    position: absolute;
    left: 1.5rem;
    .moduleName {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      h5 {
        margin: 0;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .navTabs {
    flex-wrap: wrap !important;
    .moduleNameContainer {
      position: relative;
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}
