.paymentTitle {
  color: #1fa8e9 !important;
  :global {
    span {
      color: #1fa8e9;
    }
  }
}

.formContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;

  :global {
    .custom-row {
      margin: 0;
      padding: 16px;
      border: 1px solid #d8d8d8;
      border-radius: 4px;
    }
    .form {
      width: 60%;
    }
    .personal-identification {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-end;
      justify-content: center;
      padding: 0 32px;
      div {
        padding: 0;
        &.identification-selector {
          select {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        &.identification-expire {
          input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
        &.identification {
          input {
            border-left: none;
            border-right: none;
            border-radius: 0;
          }
        }
      }
    }
    .description {
      width: 38%;
      &__advice {
        color: #858585;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 4px;
        text-align: center;
        i {
          font-size: 1.2rem;
        }
        span {
          font-style: italic;
        }
      }
      &__text {
        text-align: center;
        margin: 16px 0;
        span {
          color: #1fa8e9;
        }
      }
      &__action {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
      }
      .example-card {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 8px;
        background-color: #f9f9f9;
        max-width: fit-content;
        margin: auto;
        padding: 1rem;
        border-radius: 8px;
        margin-bottom: 16px;

        .user {
          background-color: #e5e5e5;
          padding: 1px 8px;
          color: #f9f9f9;
          border-radius: 160px;
          font-size: 1.8rem;
        }

        .placeholder {
          border-radius: 16px;
          min-height: 12px;
        }
        .last-placeholder {
          max-width: 40px;
        }
      }
    }
  }
}

.favoriteButton {
  color: #6b778c !important;
  background-color: #f3f3f3 !important;
  border: 1px solid #d8d8d8 !important;

  :global {
    span,
    i {
      font-size: 16px;
      margin-right: 0.5rem !important;
    }
  }

  &:hover {
    background-color: #1fa8e9 !important;
    color: white !important;
  }
}
