.pageTitle {
  padding: 1rem 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid #7a869a;
  h5 {
    color: #7a869a;
    margin: 0;
  }
  :global {
    .breadcrumb {
      background-color: transparent;
      padding: 0;
      margin: 0;
    }
  }
}
