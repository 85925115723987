.modal {
  max-width: 400px !important;
}

.modalContainer {
  text-align: center;
  :global {
    .general-information {
      margin: 16px 0;
      &__name {
        span {
          font-weight: 600;
        }
      }
    }
    .amount-information {
      margin: 16px 0;
      &__amount {
        color: #1fa8e9;
        font-weight: bold;
        font-size: 1.5rem;
      }
    }

    .email-container {
      margin: 16px 0;
      &__email,
      &__favorite > span {
        color: #1fa8e9;
      }
      label {
        margin: 0 !important;
      }
    }

    .advice {
      margin: 16px 0;
    }

    .actions {
      gap: 16px;
      margin-bottom: 32px;
    }
  }
}

.modalHeader {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 16px 0;
  :global {
    svg {
      width: 160px;
    }
    .cash {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: flex-end;
      margin-top: auto;
      margin-bottom: 6px;
      .icon-container {
        background-color: #5cffae;
        color: white;
        padding: 1px 8px;
        border-radius: 2px;
        font-size: 12px;
      }
      span {
        color: #1fa8e9;
        font-weight: bold;
      }
    }
  }
}

.favoriteButton {
  color: #6b778c !important;
  background-color: #f3f3f3 !important;
  border: 1px solid #d8d8d8 !important;

  :global {
    span,
    i {
      font-size: 16px;
      margin-right: 0.5rem !important;
    }
  }

  &:hover {
    background-color: #1fa8e9 !important;
    color: white !important;
  }
}
