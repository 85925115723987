.selectContainer {
  max-width: 210px;
  margin-bottom: 1rem;
  :global {
    .form-control {
      background-color: #fafbfc;
      border: 2px solid #dfe1e6;
      text-align: center;
    }
  }
}

.servicesContainer {
  max-width: 992px;
  margin: auto;
}
