.card {
  background-color: #fff;
  border: 1px solid #b3bac5;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  &:hover {
    border-color: #1fa8e9;
  }
  .cardBody {
    display: flex;
    gap: 1rem;
    position: relative;
    .default {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .cardFooter {
    display: flex;
    justify-content: flex-end;
    .btnIcon {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}
