.paymentHeader {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
}

.paymentTitle {
  color: #1fa8e9 !important;
  :global {
    span {
      color: #1fa8e9;
    }
  }
}

.favoriteButton {
  color: #6b778c !important;
  background-color: #f3f3f3 !important;
  border: 1px solid #d8d8d8 !important;

  &:hover {
    background-color: #1fa8e9 !important;
    color: white !important;
  }
}

.dashboardBottom {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  :global {
    .record-container {
      width: 59%;
      border: 1px solid #d8d8d8;
      border-radius: 4px;
      padding: 16px 8px;

      &__title {
        color: #75c9f1;
        font-weight: 600;
        text-decoration: underline;
      }

      &__records {
        display: flex;
        flex-flow: column wrap;
        gap: 1rem;
        margin-top: 1rem;
      }
    }
    .favorite-container {
      width: 40%;
      border: 1px solid #d8d8d8;
      border-radius: 4px;
      padding: 16px 8px;
    }
  }
}
