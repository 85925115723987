.cardContainer {
  display: flex;
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  :global {
    .card-description {
      display: flex;
      gap: 1rem;
      align-items: center;
      .user-icon {
        background-color: #e0f6ff;
        color: #1fa8e9;
        border-radius: 80px;
        padding: 8px 16px;
        font-size: 1.2rem;
        font-weight: bold;
      }

      .record-info {
        &__user-name {
          color: #8a8a8a;
          font-weight: 600;
        }
        &__action {
          color: #255c94;
          background-color: white;
          border: none;
          cursor: pointer;
          padding: 0;
          text-decoration: underline;
        }
      }
    }
    .card-action {
      display: flex;
      flex-flow: column wrap;
      align-items: flex-end;
      span {
        cursor: pointer;
        font-size: 1.2rem;
        color: #707070;
        padding: 0;
        
        &:hover {
          color: #b50000;
        }
      }
    }
  }
}
